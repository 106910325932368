import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BsStar, BsStarFill, BsPencilFill, BsFillTrashFill } from "react-icons/bs";
import { BiRefresh, BiSolidMicrophone, BiMicrophone } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaInfoCircle, FaCopy } from "react-icons/fa";

import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/prism';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Prompt(props) {

    const [messages, setMessages] = useState([]);
    const [executing, setExecuting] = useState(false);

    const [loadingHistory, setLoadingHistory] = useState(true);
    const [histories, setHistories] = useState([]);
    const [historyId, setHistoryId] = useState(null);

    const [followUp, setFollowUp] = useState("");

    const [followUpLines, setFollowUpLines] = useState(1);

    const [user, setUser] = useState(null);
    const [limitReached, setLimitReached] = useState(false);

    const [recording, setRecording] = useState(false);
    const [recordingProcessing, setRecordingProcessing] = useState(false);

    useEffect(() => {
      load();
    }, []);

    const load = () => {
      loadHistories();
      loadUser();
    }

    const loadHistories = async () => {
      const histories = await fetchHistories(props.token.tokenString, -1);
      setHistories(histories);
      setLoadingHistory(false);
    }

    const loadUser = async () => {
      const user = await fetchUser(props.token.tokenString, props.setToken);
      setUser(user);
      if (user.tokenUsageToday >= user.tokenLimit && user.tokenLimit > 0) {
        setLimitReached(true);
      }
    }

    useEffect(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, [messages, executing]);

    const execute = async (executingFollowUp) => {
      stopRecording();
      setExecuting(true);
      setFollowUp("");
      setFollowUpLines(1);
      let currentMessages = messages;
      currentMessages.push({role: "user", content: followUp});

      console.log(currentMessages);

      const response = await fetchExecuteAny(props.token.tokenString, setLimitReached, JSON.stringify(currentMessages));
      if (response == null) {
        setExecuting(false);
        return;
      }
      let newMessage = {role: "assistant", content: response};
      setMessages([...currentMessages, newMessage]);

      setExecuting(false);

      //finding the history title
      var title = "some title";

      //saving the history
      /*var newHistoryId = await fetchPostHistory(props.token.tokenString, historyId, promptId, truncate(title, 16), [...currentMessages, newMessage]);
      setHistoryId(newHistoryId);
      var newHistories = histories;
      for (var i = 0; i < newHistories.length; i++) {
        console.log("copmaring " + newHistories[i].id + " to " + newHistoryId);
        if (newHistories[i].id == newHistoryId) {
          newHistories[i].messages = JSON.stringify([...currentMessages, newMessage]);
        }
      }
      console.log(newHistories);
      setHistories(newHistories);*/

      console.log(newMessage);
    }

    const newSession = async () => {
      resetTranscript();
      setMessages([]);
      setHistoryId(null);

      const histories = await fetchHistories(props.token.tokenString, -1);
      setHistories(histories);
    }

    const deleteHistory = async (historyId) => {
      setMessages([]);
      setHistoryId(null);

      let newHistories = [];
      for (var i = 0; i < histories.length; i++) {
        if (histories[i].id != historyId) {
          newHistories.push(histories[i]);
        }
      }
      setHistories(newHistories);

      await fetchDeleteHistory(props.token.tokenString, historyId);
    }

    const adjustFollowUpHeight = async (value) => {
      const lines = (value.match(/\n/g) || '').length + 1
      setFollowUpLines(lines);
    }

    const changeTab = async (item) => {
      setHistoryId(item.id);
      setMessages(JSON.parse(item.messages));
      const histories = await fetchHistories(props.token.tokenString, -1);
      setHistories(histories);
    }

    const startRecording = () => {
      setRecording(true);
      SpeechRecognition.startListening({ continuous: true, language: 'de' });
    }

    const stopRecording = () => {
      SpeechRecognition.stopListening();
      setRecording(false);
      //setRecordingProcessing(true);
      processTranscript(transcript);
      //resetTranscript();
    }

    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const toggleRecording = () => {
      if (recording) {
        stopRecording();
      } else {
        startRecording();
      }
    }

    const processTranscript = async (transcript) => {
      /*let targetJsonFields = "";
      let targetJson = "{" + "" + "}";
      let recordingPrompt = "Verwende diesen Text '" + transcript.replaceAll("\"", "\\\"") + "' um das folgende JSON zu befüllen: " + targetJson.replaceAll("\"", "\\\"") + ". Gibt nur das JSON und sonst nichts zurück.";
      let recordingMessages = "[{\"role\": \"user\", \"content\": \"" + recordingPrompt + "\"}]";

      const response = await fetchExecuteAny(props.token.tokenString, setLimitReached, recordingMessages);
      try {
        let responseJson = JSON.parse(response);
      } catch (error) {
        console.log(error);
      }


      setRecordingProcessing(false);
      resetTranscript();*/
    }

    useEffect(() => {
      setFollowUp(transcript);
    }, [transcript]);

    return(
      <div className={"box fullHeight"}>
        {messages.length == 0 ? <div className="spinnerBox"><br/><br/><div className={"centerCampaign"}>Stellen Sie Ihre Frage!</div></div> : ""}
        <div className={"bottomUp"}>
          {loadingHistory ?
            "" :
            <div>
              {histories?.length > 0 ? <div><br/><span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Hier sehen Sie Ihre Verläufe mit diesem Prompt.</span><br/><br/></div> : ""}
              {histories?.map(item => (
                <div className={"tab " + (item.id == historyId ? "selected" : "")} onClick={(e) => {changeTab(item)}}>{item.title} <AiFillCloseCircle style={{verticalAlign: "bottom"}} onClick={(e) => {deleteHistory(item.id); e.stopPropagation()}} className={"closeTab"} /></div>
              ))}
            </div>
          }
          <br/>

          {messages.length < 1 ? "" : messages?.slice().map((item, index) => (
            <div>
              <div className={"messageBox"}>
                <div className={"messageIcon " + (item.role == "user" ? "" : "botIcon")}>{item.role == "user" ? props.token.user[0].toUpperCase() : "h"}</div>
                <div className={"messageContent messageContentCut"}>
                  <ReactMarkdown
                    children={item.content}
                    components={{
                      code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                          <div>
                            <SyntaxHighlighter style={nightOwl} language={match[1]} PreTag="div" children={String(children).replace(/\n$/, '')} {...props} />
                            <div className={"copy"} onClick={(e) => navigator.clipboard.writeText(String(children))} text={String(children).replace(/\n$/, '')}>
                              <button className={"copy"}><FaCopy /> Kopieren</button> 
                            </div>
                          </div>
                          ) : (
                              <code className={className} {...props} />
                            )
                      }
                    }}
                  />                  
                </div>
              </div>
            </div>
          ))}
          {messages.length > 0 ? <><div className={"actionButton"}><button className={"newSession"} onClick={(e) => newSession()}><BiRefresh /> Neue Session</button></div></> : ""}
          {executing ? <div><br/><div className="spinnerBox"><AiOutlineLoading3Quarters className="spinner" /> Lade...</div><br/></div> : ""}          
          <br/>
          {loadingHistory ? <div><br/></div> : ""}
          <br/><br/><br/><br/>
          <div className={"followUp"}>
            {
              !(browserSupportsSpeechRecognition && !limitReached) ? "" :
              <>
                <button className={"recorder recorderSmall " + (recording ? "recording" : "")} onClick={(e) => toggleRecording()}>
                  {recordingProcessing ? <AiOutlineLoading3Quarters className="plainSpinner" /> : <>{recording ? <BiSolidMicrophone /> : <BiMicrophone />}</>}
                </button>
              </>
            }
            {limitReached ? "" :
              <>
                <textarea style={{height: Math.min((followUpLines - 1) * 19 + 23, 150)}} placeholder="Ihre Anfrage..." className={"followUp followUpReduced"} onKeyPress={(ev) => { if (ev.key === "Enter" && !ev.shiftKey) { ev.preventDefault(); execute(true); }}} value={followUp} onChange={(e) => {setFollowUp(e.target.value); adjustFollowUpHeight(e.target.value)}} />
                <button onClick={(e) => execute(true)}>Senden</button>
              </>
            }
            {limitReached ? <span className="message"><br/>Ihr Tokenlimit von {user.tokenLimit} für den heutigen Tag ist bereits aufgebraucht!<br/><br/> </span> : ""}
          </div>
        </div>
      </div>
    )
}

function fetchExecuteAny(token, setLimitReached, messages) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: messages
    };

    return fetch(Config.apiBaseUrl + '/executeAny', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setLimitReached(true);
            return null;
          }
          return response.text()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchHistories(token, promptId) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/history/' + promptId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          console.log(data);
          return data;
        }
      );
}

function fetchPostHistory(token, historyId, promptId, title, messages) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({id: historyId, title: title, messages: JSON.stringify(messages)})
    };

    return fetch(Config.apiBaseUrl + '/prompt/' + promptId + '/histories', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.text();
        }
      )
      .then(
        data => {
          console.log(data);
          return data;
        }
      );
}

function fetchDeleteHistory(token, historyId) {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/history/' + historyId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUser(token, setToken) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/self', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

function findHistoryById(histories, id) {
  for (var i = 0; i < histories.length; i++) {
    if (histories[i].id == id) {
      return histories[i];
    }
  }
}

export default Prompt;
