import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

function Profile(props) {

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState("");

    const [password, setPassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");

    useEffect(() => {
      loadUser();
    }, []);

    const updatePassword = async () => {
        setPasswordMessage("Setze Passwort...");
        const response = await fetchUpdatePassword(props.token.tokenString, props.token.user, password);
        setPassword("");
        setPasswordMessage("Passwort gesetzt!");
    }

    const logout = () => {
       localStorage.clear();
       props.setToken(null);
       document.location.href = "/";
    }

    const loadUser = async () => {
      const loadedUser = await fetchUser(props.token.tokenString, props.setToken);
      setUser(loadedUser);
      setLoading(false);
    }

    return(
      <div className={"box"}>
        <h1>{loading ? "Lade..." : ""}{user?.firstName} {user?.lastName} ({user?.organization})</h1>
        Eingeloggt als <b>{props.token.user}</b> <button className="plain" onClick={(e) => logout()}>Logout</button><br/><br/>
        <hr/>
        <h2>Tokennutzung</h2>
        <b>{user.tokenUsage}</b> Verbrauch gesamt<br/>
        <b>{user.tokenLimit == -1 ? "∞" : user.tokenLimit}</b> Tageslimit<br/>
        <b>{user.tokenUsageToday}</b> Verbrauch heute<br/>
        <b>{user.tier}</b> Tier<br/>
        <hr/><br/>
        <b>Setzen Sie hier ein neues Passwort</b><br/><br/>
        <input className="large left" type="password" value={password} onChange={(e) => setPassword(e.target.value)} /> <button onClick={(e) => updatePassword()}>Neues Passwort speichern</button> <span className="message">{passwordMessage}</span>
      </div>
    )
}

function fetchUser(token, setToken) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/self', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUpdatePassword(token, email, password) {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ email: email, password: password })
    };

    return fetch(Config.apiBaseUrl + '/authentication/updatePassword', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}



export default Profile;
