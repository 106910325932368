import './App.css';

import { BsFillPlusCircleFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

import React, { useState, useEffect } from 'react';

function Header(props) {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggle = () => {
      setMenuOpen(!menuOpen);
    }

    return(
      <div>
        <div className={"header"}>
          <div className={"headerLeft"}>
            <a className={"clear"} href="/">🤖 hPrompt</a>
          </div>
          <div className={"headerRight"}>
            <button onClick={(e) => document.location.href="/edit"}><BsFillPlusCircleFill style={{fontSize: "17px", verticalAlign: "top"}}/> Prompt erstellen</button>
            <button onClick={(e) => toggle()} className={"hamburger " + (menuOpen ? "hamburgerOpen" : "")}><GiHamburgerMenu style={{fontSize: "17px", verticalAlign: "top"}} /></button>
          </div>
        </div>
        <div className={"menu " + (menuOpen ? "" : "hidden")}>
          <a href="/">Home</a>
          <hr/><a href="/profile">Mein Profil</a>
          {props.token?.roles?.includes("admin") ? <div><hr/><a href="/admin">Administration</a></div> : ""}
          {props.token?.roles?.includes("admin") ? <div><hr/><a href="/prompts/all">Alle Promts</a></div> : ""}
          <div className="menuBottom">

          </div>
        </div>
      </div>
    )
}

export default Header;
