import './App.css';

import Config from './Config.js';
import Thumb from './Thumb.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Tag(props) {

    const [prompts, setPrompts] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    let { tag } = useParams();

    useEffect(() => {
      loadPrompts();
      //loadUsers();
    }, []);

    const loadPrompts = async () => {
      setLoading(true);
      const prompts = await fetchByTag(props.token.tokenString, tag);
      setLoading(false);
      setPrompts(prompts);
    }

    const loadUsers = async () => {
      setLoading(true);
      const users = await fetchUsersByTag(props.token.tokenString, tag);
      setLoading(false);
      setUsers(users);
    }

    return(
      <div className={"box"}>
        <a className={"black"} href="/">Zurück</a><br/><br/>
        <h1 className={"tag"}>{tag.toUpperCase()}</h1>
        {loading ? <div className="spinnerBox"><br/><br/><AiOutlineLoading3Quarters className="spinner" /> Lade Prompts für Kategorie...</div> : ""}
        {
          true || !props.token.roles[0].includes("admin") || loading ? "" :
          <div className="adminBox">
            Folgende Nutzer sind der Kategorie <b>{tag}</b> zugeordnet<br/><br/>
            {
              users?.map(item => (
                <span className="">{item.email}</span>
              ))
            }
          </div>
        }
        {prompts == null ? "Loading for tag..." : prompts?.map(item => (
          <a href={"/prompt/" + item.id}><Thumb prompt={item} className={"thumbSmall"} /></a>
        ))}
      </div>
    )
}


function fetchByTag(token, tag) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/tag/' + tag, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUsersByTag(token, tag) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/usersForTag/' + tag, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

export default Tag;
