import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

function ForgotPassword(props) {

    let { secret } = useParams();

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = async () => {
        setMessage("Sende E-Mail...");
        const response = await fetchForgotPassword(email);
        setMessage("Sie erhalten eine E-Mail mit dem Wiederherstellungslink. Die Übermittlung kann einige Minuten dauern - prüfen Sie auch Ihren Spamordner!");
    }

    return(
      <div className={"box loginBox"}>
        <h1>Passwort vergessen</h1>
        <b>Geben Sie Ihre E-Mail Adresse ein. Sie erhalten eine Nachricht mit einem Link zur Wiederherstellung des Passworts.</b><br/><br/>
        <input placeholder="Ihre E-Mail Adresse" className="large" type="email" value={email} onChange={(e) => setEmail(e.target.value)} /><br/><br/>
        {message == "" ? "" : <div><b>{message}</b><br/><br/></div>}
        <button disabled={email.length == 0} className={email.length == 0 ? "disabled" : ""} onClick={(e) => sendEmail()}>Wiederherstellungslink anfordern</button>
      </div>
    )
}

function fetchForgotPassword(email) {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, linkBase: Config.appBaseUrl + "/setPassword/" })
    };

    return fetch(Config.apiBaseUrl + '/authentication/forgotPassword', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}


export default ForgotPassword;
