import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

function SetPassword(props) {

    let { secret } = useParams();

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const [message, setMessage] = useState("");

    const savePassword = async () => {
        setMessage("Setze Passwort...");
        const response = await fetchSetPassword(secret, password);
        const token = await fetchToken(secret, password);
        localStorage.setItem("session", JSON.stringify(token));
        document.location.href = "/";
    }

    const checkPasswords = async (password, repeatPassword) => {
        if (password != repeatPassword && repeatPassword.length != 0) {
          setMessage("Die Passwörter stimmen nicht überein");
        } else {
          setMessage("");
        }
    }

    return(
      <div className={"box loginBox"}>
        <h1>Passwort setzen</h1>
        <b>Setzen Sie Ihr neues Passwort!</b><br/><br/>
        <input placeholder="Passwort" className="large" type="password" value={password} onChange={(e) => {setPassword(e.target.value); checkPasswords(e.target.value, repeatPassword)}} /><br/><br/>
        <input placeholder="Passwort wiederholen" className="large" type="password" value={repeatPassword} onChange={(e) => {setRepeatPassword(e.target.value); checkPasswords(e.target.value, password)}} /><br/><br/>
        {message == "" ? "" : <div><b>{message}</b><br/><br/></div>}
        <button disabled={message.length > 0 || repeatPassword.length == 0} className={message.length > 0 || repeatPassword.length == 0 ? "disabled" : ""} onClick={(e) => savePassword()}>Passwort setzen</button>
      </div>
    )
}

function fetchSetPassword(secret, password) {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ secret: secret, newPassword: password })
    };

    return fetch(Config.apiBaseUrl + '/authentication/setPassword', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchToken(secret, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ secret: secret, password: password })
    };

    return fetch(Config.apiBaseUrl + '/authentication/loginWithSecret', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}


export default SetPassword;
