import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BsStar, BsStarFill, BsPencilFill, BsFillTrashFill } from "react-icons/bs";
import { BiRefresh, BiSolidMicrophone, BiMicrophone } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";

import ReactMarkdown from 'react-markdown';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Prompt(props) {

    let targetJson = {title: "", firstName: "", lastName: "", birthDate: "", email: "", phone: "", country: "", city: "", street: "", streetNumber: "", zipCode: ""};

    const [data, setData] = useState(targetJson);

    const [recording, setRecording] = useState(false);
    const [recordingProcessing, setRecordingProcessing] = useState(false);

    const { transcript, resetTranscript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const startRecording = () => {
      setRecording(true);
      SpeechRecognition.startListening({ continuous: false, language: 'de' });
    }

    /*useEffect(() => {
        intermediateProcessing(transcript, false);
    },[transcript]);*/

    useEffect(() => {
        if (!listening && transcript.length > 0) {
          console.log('processing: ' + transcript);
          intermediateProcessing(transcript, false);
          startRecording();
        }
    },[listening]);

    const [thinking, setThinking] = useState(false);

    const intermediateProcessing = async (transcript, final) => {
      //while (true/*recording && transcript != ""*/) {
        if (thinking && !final) {
          return;
        }
        //setThinking(true);
        //console.log("intermediate processing: " + transcript);
        let targetJsonString = JSON.stringify(data);
        let recordingPrompt = "Verwende folgendes Transkript einer Tonaufnahme '" + transcript.replaceAll("\"", "\\\"") + "' um das folgende JSON zu vervollständigen: " + targetJsonString.replaceAll("\"", "\\\"") + ". Schreibe das Datum im ISO 8601 Format in das Feld. Triff keine Annahmen über Werte, die du nicht kennst. Versuche die Stadt und das Land und die Postleitzahl aus der Adresse zu bestimmen. Die Postleitzahl kann aus dem Ort oder dem Bezirk bestimmt werden. Wird das selbe Feld im Transkript mehrmals angesprochen, ist der spätere Wert wichtiger, da es sich um Verbesserungen handeln kann. Gib nur das JSON und sonst nichts zurück.";
        //let recordingMessages = "[{\"role\": \"assistant\", \"content\": \"" + JSON.stringify(data).replaceAll("\"", "\\\"") + "\"},{\"role\": \"user\", \"content\": \"" + recordingPrompt + "\"}]";
        let recordingMessages = "[{\"role\": \"user\", \"content\": \"" + recordingPrompt + "\"}]";

        //console.log(recordingMessages);
        //console.log(props.token.tokenString);

        console.log("this is the prompt: " + recordingPrompt);

        console.log("start processing");
        const response = await fetchExecuteAny(props.token.tokenString, recordingMessages);
        try {
          let responseJson = JSON.parse(response);
          setData(responseJson);
        } catch (error) {
          console.log(error);
        }
        console.log("finish processing");
        setThinking(false);
      //}
    }

    const stopRecording = () => {
      SpeechRecognition.stopListening();
      setRecording(false);
      setRecordingProcessing(true);
      intermediateProcessing(transcript, true);
      //processTranscript(transcript);
      //resetTranscript();
    }

    const toggleRecording = () => {
      if (recording) {
        stopRecording();
      } else {
        startRecording();
      }
    }

    /*const processTranscript = async (transcript) => {
      let targetJsonString = JSON.stringify(targetJson);
      let recordingPrompt = "Verwende diesen Text '" + transcript.replaceAll("\"", "\\\"") + "' um das folgende JSON zu befüllen: " + targetJsonString.replaceAll("\"", "\\\"") + ". Schreibe das Datum im ISO 8601 Format in das Feld. Triff keine Annahmen über Werte, die du nicht kennst. Gib nur das JSON und sonst nichts zurück.";
      let recordingMessages = "[{\"role\": \"user\", \"content\": \"" + recordingPrompt + "\"}]";

      console.log(recordingMessages);
      console.log(props.token.tokenString);

      const response = await fetchExecuteAny(props.token.tokenString, recordingMessages);
      try {
        let responseJson = JSON.parse(response);
        setData(responseJson);
      } catch (error) {
        console.log(error);
      }
      //setRecordingProcessing(false);
      //resetTranscript();
    }*/

    return(
      <div className={"box"}>
        {
          !(browserSupportsSpeechRecognition) ? "" :
          <div>
            <button className={"recorder " + (recording ? "recording" : "")} onClick={(e) => toggleRecording()}>
              {recordingProcessing ? <AiOutlineLoading3Quarters className="plainSpinner" /> : <>{recording ? <BiSolidMicrophone /> : <BiMicrophone />}</>}
            </button>
            {
              !recording && !recordingProcessing ? <span className="plainMessage">Klicken Sie auf das Mikrofon, um die Spracheingabe zu nutzen</span> : ""
            }
            {
              recording ? <span className="plainMessage">{transcript}</span> : ""
            }
            <br/>
          </div>
        }
        <input style={{width: "300px"}} value={data.title} onChange={(e) => {let d = {...data}; d.title = e.target.checked; setData(d)}} /> Title<br/>
        <input style={{width: "300px"}} value={data.firstName} onChange={(e) => {let d = {...data}; d.firstName = e.target.checked; setData(d)}} /> First name<br/>
        <input style={{width: "300px"}} value={data.lastName} onChange={(e) => {let d = {...data}; d.lastName = e.target.checked; setData(d)}} /> Last name<br/>

        <input style={{width: "300px"}} value={data.birthDate} onChange={(e) => {let d = {...data}; d.birthDate = e.target.checked; setData(d)}} /> Birth date<br/>
        <input style={{width: "300px"}} value={data.email} onChange={(e) => {let d = {...data}; d.email = e.target.checked; setData(d)}} /> email<br/>
        <input style={{width: "300px"}} value={data.phone} onChange={(e) => {let d = {...data}; d.phone = e.target.checked; setData(d)}} /> phone<br/>

        <input style={{width: "300px"}} value={data.country} onChange={(e) => {let d = {...data}; d.country = e.target.checked; setData(d)}} /> Country<br/>
        <input style={{width: "300px"}} value={data.city} onChange={(e) => {let d = {...data}; d.city = e.target.checked; setData(d)}} /> City<br/>
        <input style={{width: "300px"}} value={data.street} onChange={(e) => {let d = {...data}; d.street = e.target.checked; setData(d)}} /> Street<br/>
        <input style={{width: "300px"}} value={data.streetNumber} onChange={(e) => {let d = {...data}; d.streetNumber = e.target.checked; setData(d)}} /> Street number<br/>
        <input style={{width: "300px"}} value={data.zipCode} onChange={(e) => {let d = {...data}; d.zipCode = e.target.checked; setData(d)}} /> Zip code<br/>
      </div>
    )
}

function fetchExecuteAny(token, messages) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: messages
    };

    console.log(requestOptions);

    return fetch(Config.apiBaseUrl + '/executeAny', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.text()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUser(token, setToken) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/self', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

function findHistoryById(histories, id) {
  for (var i = 0; i < histories.length; i++) {
    if (histories[i].id == id) {
      return histories[i];
    }
  }
}

export default Prompt;
