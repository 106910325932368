import './App.css';
import Config from './Config.js';

import React, { useState, useEffect } from 'react';

function Login(props) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const tokenRequestCallback = (response) => {
      if (response != null && response.status == null) {
        this.setState(prevState => ({ loginResponse: {success: true}, token: response }));
        localStorage.setItem("session", JSON.stringify(response));
      } else {
        localStorage.clear();
        this.setState(prevState => ({ loginResponse: {success: false}, token: null }));
      }
    }

    const login = async () => {
       const token = await fetchToken(username, password);
       if (token !== null) {
          localStorage.setItem("session", JSON.stringify(token));
          props.setToken(token);
          document.location.href = "/";
       } else {
          setMessage("E-Mail oder Passwort falsch!")
       }
    }

    return(
      <div className={"box loginBox"}>
        <div className={"slogan"}>
          🤖 hPrompt - die kollaborative Prompt-Library der Huemer-Group
          <div className={"smallSlogan"}>Wählen Sie die besten Prompts aus unserer Sammlung aus und bringen Sie Ihr AI-Erlebnis auf ein neues Level!</div>
        </div>
        <input className="large" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Email" /><br/><br/>
        <input className="large" onKeyPress={(ev) => { if (ev.key === "Enter") { ev.preventDefault(); login(); }}} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" type="password" /><br/><br/>
        <button onClick={(e) => login()}>Login</button>
        {message == "" ? "" : <div><br/><b>{message}</b></div>}
        <br/><br/>
        <a className="subtle" href="/forgotPassword">Passwort vergessen</a>
      </div>
    )
}

function fetchToken(email, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password })
    };

    return fetch(Config.apiBaseUrl + '/authentication/login', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

export default Login;
