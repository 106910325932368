import './App.css';
import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { BsStar, BsStarFill } from "react-icons/bs";
import { AiFillRobot } from "react-icons/ai";
import { FaEarthEurope } from "react-icons/fa6";
import { BiSolidLockAlt } from "react-icons/bi";

function Thumb(props) {

    return(
      <div className={"thumb " + props.className}>
        {props.prompt.parentId != null && props.userOwnsPrompt ? <span className="colorBox">Eigene Version</span> : ""}
        <div className={"thumbHeader " + (props.prompt.parentId != null && props.userOwnsPrompt ? "thumbHeaderSmaller" : "")}>
          {props.prompt.title}
        </div>
        <div className={"thumbFooter"}>
          <div className={"thumbLeft"}>
            {props.prompt.usageCount} Interaktionen
          </div>
          <div className={"thumbRight"}>
            <BsStarFill style={{color: "#000", fontSize: "10px"}} /> {props.prompt.favoriteCount} {props.prompt.isPublic ? <span><FaEarthEurope style={{color: "#000", fontSize: "10px"}} /> Öffentlich</span> : <span><BiSolidLockAlt style={{color: "#000", fontSize: "10px"}} /> Privat</span>}
          </div>
        </div>
      </div>
    )
}

export default Thumb;
