import './App.css';

import Config from './Config.js';
import Thumb from './Thumb.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

function AllPrompts(props) {

    const [prompts, setPrompts] = useState([]);
    const [skip, setSkip] = useState(0);
    const [allPrompts, setAllPrompts] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    let { userId } = useParams();

    useEffect(() => {
      load();
    }, []);

    const load = async () => {
      setLoading(true);
      if (userId == undefined) {
        const newPrompts = await fetchAll(props.token.tokenString, skip, 30);
        setPrompts([...prompts, ...newPrompts]);
        if (newPrompts.length < 30) {
          setAllPrompts(true);
        }
        setSkip(skip + 30);
      } else {
        const prompts = await fetchAllByUser(props.token.tokenString, userId);
        setPrompts(prompts);
        const user = await fetchUser(props.token.tokenString, props.setToken, userId);
        console.log(user);
        setUser(user);
      }
      setLoading(false);
    }

    return(
      <div className={"box"}>
        <h1 className={"tag"}>{userId == undefined ? "Alle Prompts" : ""}{user?.firstName} {user?.lastName}</h1>
        {prompts?.map(item => (
          <a href={"/prompt/" + item.id}><Thumb prompt={item} className={"thumbSmall"} /></a>
        ))}
        {user != null && prompts.length == 0 ? "Dieser Nutzer hat noch keine Prompts angelegt" : ""}
        {loading ? "Lade..." : ""}
        <br/><br/>
        {(loading || allPrompts || userId != undefined) ? "" : <button className="small" onClick={(e) => load()}>Weitere Prompts laden</button>}
      </div>
    )
}


function fetchAll(token, skip, limit) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/all/' + skip + '/' + limit, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchAllByUser(token, userId) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/all/user/' + userId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUser(token, setToken, id) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/' + id, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

export default AllPrompts;
