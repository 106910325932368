import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

import { FaInfoCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

function EditUser(props) {

    let { userId } = useParams();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const [emailValid, setEmailValid] = useState(true);
    const [emailFree, setEmailFree] = useState(true);
    const [userCreated, setUserCreated] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");

    const [sendEmailMessage, setSendEmailMessage] = useState("");

    const [tagQuery, setTagQuery] = useState("");
    const [tagResults, setTagResults] = useState([]);
    const [chosenTags, setChosenTags] = useState([]);
    const [tagsLoading, setTagsLoading] = useState(false);

    useEffect(() => {
      if (userId != undefined) {
        load();
      } else {
        setUser({});
        setLoading(false);
      }
    }, []);

    const load = async () => {
      const loadedUser = await fetchUser(props.token.tokenString, props.setToken, userId);
      setUser(loadedUser);
      setLoading(false);
    }

    const save = async (redirect, user) => {
      if (userId == undefined && redirect != false) {
        setLoading(true);
        const response = await fetchCreateUser(props.token.tokenString, user);
        console.log(response);
        setUser(response);
        setLoading(false);
        setUserCreated(true);
      } else {
        await fetchEditUser(props.token.tokenString, user);
        if (redirect) {
          document.location.href = "/admin";
        }
      }
    }

    const deleteUser = async () => {
      const response = await fetchDeleteUser(props.token.tokenString, user);
      document.location.href = "/admin";
    }

    const checkEmailAvailable = async (email) => {
      if (email.length == 0) {
        setEmailValid(false);
        setEmailFree(true);
        return;
      }

      if (!/^.+@.+$/.test(email)) {
        setEmailValid(false);
        return;
      }

      setEmailValid(true);
      const response = await fetchEmailAvailable(email);
      console.log(response);
      setEmailFree(response);
    }

    const updatePassword = async () => {
      setPasswordMessage("Setze Passwort...");
      const response = await fetchUpdatePassword(props.token.tokenString, user.email, password);
      setPassword("");
      setPasswordMessage("Passwort gesetzt!");
    }

    const sendEmail = async () => {
      setSendEmailMessage("Sende E-Mail...");
      const response = await fetchSendEmail(props.token.tokenString, user.email);
      setSendEmailMessage("E-Mail mit Passwort-Setzen Link versendet!");
    }

    const searchTags = async (query) => {
      query = query.replaceAll(/[^A-Za-z0-9_@.\-]+/g,"");
      if (query.length > 0) {
        setTagsLoading(true);
        const response = await fetchSearchTag(props.token.tokenString, query);
        setTagsLoading(false);
        setTagResults(response);
      } else {
        setTagResults([]);
      }
    }

    const addTag = (tag) => {
      tag = tag.toLowerCase().replaceAll(/[^A-Za-z0-9_@.\-]+/g,"");
      if (!user?.tags?.includes(tag)) {
        let u = {...user};
        if (u.tags == null) {
          u.tags = [];
        }
        u.tags = [...u.tags, tag];
        setUser(u);
        save(false, u);
      }
    }

    const removeTag = (tag) => {
      if (user?.tags?.includes(tag)) {
        let u = {...user};
        u.tags = u.tags.filter(item => item !== tag);
        setUser(u);
        save(false, u);
      }
    }

    return(
      <div className={"box"}>
          <h1>Nutzer {userId == undefined ? "anlegen" : "bearbeiten"}</h1>
          {(loading || userId == undefined) ? "" : <button onClick={(e) => document.location.href = "/prompts/all/" + user.id} className="plain">Prompts von {user.firstName} {user.lastName} anzeigen</button>}
          {loading ? "Lade..." :
            <div>
              {userCreated ? "" :
                <div>
                  <label>Email</label>
                  <input disabled={userId != undefined} placeholder="E-Mail Adresse" type="email" className={"large " + (userId != undefined ? "disabled" : "")} value={user.email} onChange={(e) => {let u = {...user}; u.email = e.target.value; setUser(u); checkEmailAvailable(e.target.value)}} />
                  {!emailFree ? <div className="error">Diese E-Mail Adresse ist bereits vergeben.</div> : ""}
                  {!emailValid ? <div className="error">Die Eingabe ist keine gültige E-Mail Adresse.</div> : ""}
                  <label>Vorname</label>
                  <input placeholder="Vorname" className="large" value={user.firstName} onChange={(e) => {let u = {...user}; u.firstName = e.target.value; setUser(u)}} />
                  <label>Nachname</label>
                  <input placeholder="Nachname" className="large" value={user.lastName} onChange={(e) => {let u = {...user}; u.lastName = e.target.value; setUser(u)}} />
                  <label>Organisation</label>
                  <input placeholder="Organisation" className="large" value={user.organization} onChange={(e) => {let u = {...user}; u.organization = e.target.value; setUser(u)}} />
                  <label>Rolle</label>
                  <select value={user.roles != null && user.roles?.length != 0 ? user.roles[0] : ""} onChange={(e) => {let u = {...user}; u.roles = [e.target.value]; setUser(u)}} >
                    <option value="">Nutzer</option>
                    <option value="admin">Admin</option>
                  </select>
                  <hr/>
                  <label>Tier</label>
                  <select value={user.tier} onChange={(e) => {let u = {...user}; u.tier = e.target.value; setUser(u)}} >
                    <option value="0">Tier 0 (Beschränkte Anzahl an Tokens)</option>
                    <option value="1">Tier 1 (Unbeschränke Anzahl an Tokens)</option>
                  </select>
                  {userId == undefined ? "" :
                    <div>
                      <label>Tokennutzung gesamt</label>
                      <input placeholder="0" className="large disabled" value={user.tokenUsage} disabled /><br/>
                      <label>Tokennutzung heute</label>
                      <input placeholder="0" className="large disabled" value={user.tokenUsageToday} disabled /><br/>
                      <label>Tokenlimit pro Tag</label>
                      <input placeholder="0" className="large disabled" value={user.tokenLimit == -1 ? "∞" : user.tokenLimit} disabled />
                    </div>
                  }
                  <hr/><br/>
                  <div><input checked={user.canAccessAllPrompts} onChange={(e) => {let u = {...user}; u.canAccessAllPrompts = e.target.checked; setUser(u)}} className="checkbox" type="checkbox" /> <b>Diesem Nutzer alle Prompts zugänglich machen.</b></div>
                  <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Wird dieses Häkchen gesetzt, kann der Nutzer auf alle Prompts zugreifen</span><br/><br/>
                  <hr/><br/>
                  <b>Diesem Nutzer zugeordnete Kategorien</b><br/>
                  <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Nach Login werden dem Nutzer Prompts aus den hier ausgewählten Kategorien angezeigt</span><br/><br/>
                  <div className="selectSelected">
                    {user?.tags?.map(item => (
                      <div className="tag">{item}  <AiFillCloseCircle style={{cursor: "pointer", verticalAlign: "top", fontSize: "16px"}} onClick={(e) => {removeTag(item); e.stopPropagation()}} className={"closeTab"} /></div>
                    ))}
                  </div>
                  <br/>
                  <input id="tagCategory" autocomplete="one-time-code" className="select" value={tagQuery} onChange={(e) => {setTagQuery(e.target.value); searchTags(e.target.value)}} placeholder="Geben Sie eine Kategorie ein..." /> <button onClick={(e) => addTag(tagQuery)}>Hinzufügen</button>
                  <div className="select">
                    {tagQuery.length == 0 ? "" : tagResults?.map(item => (
                      <div className="selectRow" onClick={(e) => addTag(item)}>{item}</div>
                    ))}
                    {tagsLoading ? <div className="selectLoading">Lade...</div> : ""}
                  </div>
                  <br/><br/>

                  <button disabled={!(emailValid && emailFree)} className={!(emailValid && emailFree) ? "disabled" : ""} onClick={(e) => save(true, user)}>Speichern</button>
                </div>
              }
              {
                !userCreated ? "" :
                <div>
                  <h2>Nutzer <a href={"/user/edit/" + user.id}>{user.firstName} {user.lastName}{user.firstName?.length + user.lastName?.length == 0 ? user.email : ""}</a> erfolgreich gespeichert!</h2>
                  <div className="note">Der Nutzer wurde angelegt, hat jedoch noch kein Passwort gesetzt.<br/><br/>Wählen Sie eine der folgenden Methoden, um das Passwort zu setzen!</div><br/>
                </div>
              }
              {
                !(!loading && (userCreated || userId != undefined)) ? "" :
                <div>
                  {
                    !(!loading && userId != undefined) ? "" :
                    <div><br/><hr/><h2>Weitere Funktionen</h2></div>
                  }
                  <b>E-Mail mit Link zum Setzen des Passworts versenden</b><br/><br/>
                  Eine E-Mail Nachricht wird an den Nutzer versendet, welche den Link zum Setzen des Passworts enthält.<br/><br/>
                  <button disabled={sendEmailMessage.length > 0} className={"plain " + (sendEmailMessage.length > 0 ? "disabled" : "")} onClick={(e) => sendEmail()} >E-Mail an {user.email} versenden</button> <span className="message">{sendEmailMessage}</span>
                  <hr/><br/>
                  <b>Link zum Setzen des Passworts manuell an den Nutzer übermitteln</b><br/><br/>
                  Sie können folgenden Link über einen sicheren Kanal an den Nutzer {user.email} übermitteln, womit dieser sein Passwort setzen kann.<br/><br/>
                  <input disabled="true" className="large disabled" value={Config.appBaseUrl + "/setPassword/" + user.secret} />
                  <hr/><br/>
                  <b>Passwort manuell setzen</b><br/><br/>
                  Sie können das Passwort des Nutzers manuell setzen.<br/><br/>
                  <input value={password} onChange={(e) => setPassword(e.target.value)} className="large left" placeholder={"Passwort setzen..."} type="password" />
                  <button disabled={password.length == 0} className={password.length == 0 ? "disabled" : ""} onClick={(e) => updatePassword()} >Passwort setzen</button> <span className="message">{passwordMessage}</span>
                  <br/>
                </div>
              }
              {
                !(!loading && userId != undefined) ? "" :
                <div>
                  <hr/>
                  <br/>
                  <b>Nutzer löschen</b><br/><br/>
                  <button className="plain" onClick={(e) => deleteUser()}>{user.email} löschen</button>
                  <br/><br/><br/><br/><br/><br/>
                </div>
              }
            </div>
          }
      </div>
    )
}

function fetchUser(token, setToken, id) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/' + id, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchCreateUser(token, user) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(user)
    };

    return fetch(Config.apiBaseUrl + '/user/create', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchEditUser(token, user) {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(user)
    };

    return fetch(Config.apiBaseUrl + '/user/edit', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchDeleteUser(token, user) {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(user)
    };

    return fetch(Config.apiBaseUrl + '/user/delete', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchEmailAvailable(email) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    };

    return fetch(Config.apiBaseUrl + '/user/available/' + email, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUpdatePassword(token, email, password) {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ email: email, password: password })
    };

    return fetch(Config.apiBaseUrl + '/authentication/updatePassword', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchSendEmail(token, email) {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ email: email, linkBase: Config.appBaseUrl + "/setPassword/" })
    };

    return fetch(Config.apiBaseUrl + '/authentication/sendSetPassword', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchSearchTag(token, query) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/searchTag/' + query, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

export default EditUser;
