import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

import { FaInfoCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiSolidUserCircle } from "react-icons/bi";

function Edit(props) {

    let { promptId } = useParams();

    const [prompt, setPrompt] = useState(null);
    const [user, setUser] = useState(null);

    const [tagQuery, setTagQuery] = useState("");
    const [tagResults, setTagResults] = useState([]);
    const [chosenTags, setChosenTags] = useState([]);
    const [tagsLoading, setTagsLoading] = useState(false);

    const [userQuery, setUserQuery] = useState("");
    const [userResults, setUserResults] = useState([]);
    const [chosenUsers, setChosenUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);

    const [placeholders, setPlaceholders] = useState([]);
    const [howToOpen, setHowToOpen] = useState(false);
    const [exampleOpen, setExampleOpen] = useState(false);

    useEffect(() => {
      console.log(promptId);
      if (promptId != undefined) {
        load();
      } else {
        setPrompt({
                     "id": "",
                     "gptSystem": "",
                     "gptUser": "",
                     "fineTuning": false,
                     "gptTemperature": 0,
                     "gptTopP": 0,
                     "favoriteCount": 0,
                     "usageCount": 0,
                     "title": "",
                     "description": "",
                     "isPublic": true,
                     "tags": [],
                     "grantedEmails": []
                   });
      }
    }, []);

    const load = () => {
      loadPrompt();
      loadUser();
    }

    const loadPrompt = async () => {
      const prompt = await fetchPrompt(props.token.tokenString, promptId);
      setPrompt(prompt);
    }

    const loadUser = async () => {
      const user = await fetchUser(props.token.tokenString);
      setUser(user);
    }

    const save = async (redirect, prompt) => {
      console.log(prompt);

      if (prompt.id.length == 0) {
        const response = await fetchCreatePrompt(props.token.tokenString, prompt);
        console.log(response);
        document.location.href = "/prompt/" + response.id;
      } else {
        const response = await fetchEditPrompt(props.token.tokenString, prompt);
        if (redirect) {
          document.location.href = "/prompt/" + response.id;
        }
      }
    }

    const deletePrompt = async () => {
        const response = await fetchDeletePrompt(props.token.tokenString, prompt);
        document.location.href = "/";
    }

    const searchTags = async (query) => {
      query = query.replaceAll(/[^A-Za-z0-9_@.\-]+/g,"");
      if (query.length > 0) {
        setTagsLoading(true);
        const response = await fetchSearchTag(props.token.tokenString, query);
        setTagsLoading(false);
        setTagResults(response);
      } else {
        setTagResults([]);
      }
    }

    const addTag = (tag) => {
      tag = tag.toLowerCase().replaceAll(/[^A-Za-z0-9_@.\-]+/g,"");
      if (!prompt?.tags?.includes(tag)) {
        let p = {...prompt};
        if (p.tags == null) {
          p.tags = [];
        }
        p.tags = [...p.tags, tag];
        setPrompt(p);

        let isDuplication = !(props.token.roles?.includes("admin") || (user != null && user.id == prompt?.ownerId));
        if (promptId != undefined && !isDuplication) {
          save(false, p);
        }
      }
    }

    const removeTag = (tag) => {
      if (prompt?.tags?.includes(tag)) {
        let p = {...prompt};
        p.tags = p.tags.filter(item => item !== tag);
        setPrompt(p);

        let isDuplication = !(props.token.roles?.includes("admin") || (user != null && user.id == prompt?.ownerId));
        if (promptId != undefined && !isDuplication) {
          save(false, p);
        }
      }
    }

    const searchUsers = async (query) => {
      query = query.replaceAll("/", "").replaceAll(/\s/g, "");
      if (query.length > 0) {
        setUsersLoading(true);
        const response = await fetchSearchUser(props.token.tokenString, query);
        setUsersLoading(false);
        setUserResults(response);
      } else {
        setUserResults([]);
      }
    }

    const addUser = (user) => {
      if (!prompt?.grantedEmails?.includes(user.email)) {
        let p = {...prompt};
        if (p.grantedEmails == null) {
          p.grantedEmails = [];
        }
        p.grantedEmails = [...p.grantedEmails, user.email];
        setPrompt(p);
        if (promptId != undefined) {
          save(false, p);
        }
      }
    }

    const removeUser = (email) => {
      if (prompt?.grantedEmails?.includes(email)) {
        let p = {...prompt};
        p.grantedEmails = p.grantedEmails.filter(item => item !== email);
        setPrompt(p);
        if (promptId != undefined) {
          save(false, p);
        }
      }
    }

    const computePlaceholders = (text) => {
      if (text == undefined) {
        return [];
      }

      const regex = "\{\{[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]+\}\}";
      let pos = text.search(regex);
      let cnt = 0;
      let p = [];
      while (pos != -1 && cnt < 100) {
        cnt++;
        let trunk = text.substring(pos + 2);
        let end = trunk.search("}}");
        let name = trunk.substring(0, end);
        p.push(name);
        text = trunk.substring(end + 2);
        pos = text.search(regex);
      }
      setPlaceholders(p);
    }

    return(
      <div className={"box editBox"}>
        {(user == null || prompt == null) && promptId != undefined ? "" : <h1>Prompt {promptId == undefined ? "erstellen" : (props.token.roles?.includes("admin") || (user != null && user.id == prompt?.ownerId) ? "bearbeiten" : "duplizieren")}</h1>}
        <div className="thumbLeft">Konfigurieren Sie Ihren Prompt.</div>
        <div className="thumbRight">{promptId == undefined ? "" : (props.token.roles?.includes("admin") || (user != null && user.id == prompt?.ownerId) ? <button className={"small"} onClick={(e) => deletePrompt()}>Prompt löschen</button> : "")}</div><br/><br/>
        {prompt == null ? "Lade Prompt..." :
          <div>
            <b>Titel</b> <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Geben Sie den Titel des Prompts ein. Dieser dient zur Verwaltung der Prompts.</span><br/><br/>
            <input placeholder="Der Titel des Prompts..." value={prompt.title} onChange={(e) => {let p = {...prompt}; p.title = e.target.value; setPrompt(p)}} /><br/><br/>
            <b>Beschreibung</b> <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Die Beschreibung erlaubt Nutzern auf einen Blick die Funktion des Prompts zu verstehen.</span><br/><br/>
            <textarea placeholder="Die Beschreibung des Prompts zur Erklärung..." value={prompt.description} onChange={(e) => {let p = {...prompt}; p.description = e.target.value; setPrompt(p)}} /><br/><br/>
            <b>Prompt</b> <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Fügen Sie den Prompt hier ein. Der Inhalt dieses Felds wird an die KI übermittelt.</span><br/><br/>
            <a className="black" onClick={(e) => {setHowToOpen(!howToOpen); setExampleOpen(false)}} >Wie schreibe ich einen Prompt?</a><span style={{display: "inline-block", marginLeft: "20px"}}></span><a className="black" onClick={(e) => {setExampleOpen(!exampleOpen); setHowToOpen(false)}} >Beispiel Prompt</a><br/><br/>
            {howToOpen ?
              <div className="explanation">
                Sie können jeden denkbare Anweisung in Ihren Prompt schreiben.<br/>
                Formulieren Sie die Aufgabe so, wie Sie dies für einen Menschen tun würden.<br/>
                Um den Prompt flexibel zu gestalten, können Sie Platzhalter einbauen.<br/>
                Diese sind mit doppelten geschwungenen Klammern gekennzeichnet.<br/>
                <div className="example">&#123;&#123;Platzhalter&#125;&#125;</div>
                Bauen Sie die Platzhalter an beliebige Stellen in Ihrem Prompt ein. Dies könnte beispielsweise so aussehen:<br/>
                <div className="example">Schreibe eine Einladung für &#123;&#123;Eventname&#125;&#125; an &#123;&#123;Empfängername&#125;&#125;</div>
              </div>
              : ""
            }
            {exampleOpen ?
              <div className="explanation">
                Hier ist ein Beispiel für einen Prompt, der ein Social-Media Posting mit einer definierten Anzahl an Wörtern erstellt:<br/>
                <div className="example">Erstelle ein Social-Media Posting zum Thema &#123;&#123;Posting Inhalt&#125;&#125;  mit einer Länge von &#123;&#123;Anzahl Wörter&#125;&#125;  Wörter</div>
                Nutzer können nun die automatisch erkannten Platzhalter über die Eingabfelder befüllen. Ein befüllter Prompt sieht zum Beispiel so aus:<br/>
                <div className="example">Erstelle ein Social-Media Posting zum Thema <b>Künstliche Intelligenz</b> mit einer Länge von <b>100</b> Wörter</div>
              </div>
              : ""
            }
            <textarea placeholder="GPT Prompt - Platzhalter werden vom System automatisiert erkannt und müssen in zwei geschwungenen Klammern eingegeben werden. Z. B. Schreibe einen Absatz über {{Thema}}" value={prompt.gptUser} onChange={(e) => {let p = {...prompt}; p.gptUser = e.target.value; setPrompt(p); computePlaceholders(e.target.value)}} /><br/><br/>

            {placeholders.length > 0 ? <b>Erkannte Platzhalter<br/><br/></b> : ""}
            {placeholders?.map(item => (
              <div className="tab" style={{cursor: "auto"}}>{item}</div>
            ))}
            {placeholders.length > 0 ? <><br/><br/></> : ""}

              <div>
                <b>Diesem Prompt zugeordnete Kategorien</b>
                <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Fügen Sie hier Kategorien zum Prompt hinzu. Anschließend können diese Kategorien zu Nutzern hinzugefügt werden.</span><br/><br/>
                <div className="selectSelected">
                  {prompt?.tags?.map(item => (
                    <div className="tag">{item}  <AiFillCloseCircle style={{cursor: "pointer", verticalAlign: "top", fontSize: "16px"}} onClick={(e) => {removeTag(item); e.stopPropagation()}} className={"closeTab"} /></div>
                  ))}
                </div>
                {prompt?.tags?.length > 0 ? <br/> : ""}
                <input className="select" value={tagQuery} onChange={(e) => {setTagQuery(e.target.value); searchTags(e.target.value)}} placeholder="Geben Sie eine Kategorie ein..." /> <button onClick={(e) => addTag(tagQuery)}>Hinzufügen</button>
                <div className="select">
                  {tagQuery.length == 0 ? "" : tagResults?.map(item => (
                    <div className="selectRow" onClick={(e) => addTag(item)}>{item}</div>
                  ))}
                  {tagsLoading ? <div className="selectLoading">Lade...</div> : ""}
                </div>
                <br/><br/>
              </div>
            {!(props?.token?.roles?.length > 0 && props.token.roles[0].includes("admin")) ? "" :
              <div>
                <b>Diesem Prompt direkt zugeordnete Nutzer</b>
                <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Nutzer können Prompts in den ihnen zugeordneten Kategorien sehen. Wenn Sie direkt einen Nutzer zu diesem Propmt hinzufügen wollen, können Sie dies hier tun.</span><br/><br/>
                <div className="selectSelected">
                  {prompt?.grantedEmails?.map(item => (
                    <div className="userTag"><BiSolidUserCircle style={{verticalAlign: "top", fontSize: "16px"}} /> {item} <AiFillCloseCircle style={{cursor: "pointer", verticalAlign: "top", fontSize: "16px"}} onClick={(e) => {removeUser(item); e.stopPropagation()}} className={"closeTab"} /></div>
                  ))}
                </div>
                {prompt?.grantedEmails?.length > 0 ? <br/> : ""}
                <input className="select" value={userQuery} onChange={(e) => {setUserQuery(e.target.value); searchUsers(e.target.value)}} placeholder="Suchen Sie nach einem Nutzer..." />
                <div className="select">
                  {userQuery.length == 0 ? "" : userResults?.map(item => (
                    <div className="selectRow" onClick={(e) => addUser(item)}>{item.email}</div>
                  ))}
                  {tagsLoading ? <div className="selectLoading">Lade...</div> : ""}
                </div>
                <br/><br/>
              </div>
            }
            <button onClick={(e) => save(true, prompt)}>Speichern</button>
          </div>
        }
      </div>
    )
}

function fetchPrompt(token, promptId) {

    /* {props?.token?.roles?.length > 0 && props.token.roles[0].includes("admin") && prompt.id.length > 0 ? <div><input checked={!prompt.isPublic} onChange={(e) => {let p = {...prompt}; p.isPublic = !e.target.checked; setPrompt(p)}} className="checkbox" type="checkbox" /> Propmt verbergen<br/><br/></div> : "" }*/

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/' + promptId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchCreatePrompt(token, prompt) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(prompt)
    };

    return fetch(Config.apiBaseUrl + '/prompt/create', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchEditPrompt(token, prompt) {

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(prompt)
    };

    return fetch(Config.apiBaseUrl + '/prompt/edit', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchDeletePrompt(token, prompt) {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(prompt)
    };

    return fetch(Config.apiBaseUrl + '/prompt/delete', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchSearchTag(token, query) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/searchTag/' + query, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchSearchUser(token, query) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/search/' + query, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUser(token, setToken) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/self', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}





export default Edit;
