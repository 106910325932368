import './App.css';

import Config from './Config.js';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BsStar, BsStarFill, BsPencilFill, BsFillTrashFill } from "react-icons/bs";
import { BiRefresh, BiSolidMicrophone, BiMicrophone } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";

import ReactMarkdown from 'react-markdown';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Prompt(props) {

    let { promptId } = useParams();

    const [prompt, setPrompt] = useState(null);
    const [placeholders, setPlaceholders] = useState([]);

    const [compiledGptSystem, setCompiledGptSystem] = useState("");
    const [compiledGptUser, setCompiledGptUser] = useState("");
    const [placeholderValues, setPlaceholderValues] = useState({});
    const [placeholderLines, setPlaceholderLines] = useState({});

    const [messages, setMessages] = useState([]);
    const [executing, setExecuting] = useState(false);

    const [loadingHistory, setLoadingHistory] = useState(true);
    const [histories, setHistories] = useState([]);
    const [historyId, setHistoryId] = useState(null);

    const [followUp, setFollowUp] = useState("");
    const [topInputChanged, setTopInputChanged] = useState(false);

    const [followUpLines, setFollowUpLines] = useState(1);

    const [favorites, setFavorites] = useState([]);

    const [user, setUser] = useState(null);
    const [limitReached, setLimitReached] = useState(false);

    const [recording, setRecording] = useState(false);
    const [recordingProcessing, setRecordingProcessing] = useState(false);

    useEffect(() => {
      load();
    }, []);

    const load = () => {
      loadPrompt();
      loadFavorites();
      loadHistories();
      loadUser();
    }

    const loadPrompt = async () => {
      const prompt = await fetchPrompt(props.token.tokenString, props.setToken, promptId);
      setPrompt(prompt);
      let placeholders = computePlaceholders(prompt?.gptSystem).concat(computePlaceholders(prompt?.gptUser));
      setPlaceholders(placeholders);
      setCompiledGptUser(prompt?.gptUser);
      setCompiledGptSystem(prompt?.gptSystem);
    }

    const loadFavorites = async () => {
      const favoritesResponse = await fetchFavorites(props.token.tokenString);
      let favorites = [];
      if (favoritesResponse != undefined) {
        favoritesResponse.forEach((p) => favorites.push(p.id));
        setFavorites(favorites);
      }
    }

    const loadHistories = async () => {
      const histories = await fetchHistories(props.token.tokenString, promptId);
      setHistories(histories);
      setLoadingHistory(false);
    }

    const loadUser = async () => {
      const user = await fetchUser(props.token.tokenString, props.setToken);
      setUser(user);
      if (user.tokenUsageToday >= user.tokenLimit && user.tokenLimit > 0) {
        setLimitReached(true);
      }
    }

    const computePlaceholders = (text) => {
      if (text == undefined) {
        return [];
      }

      const regex = "\{\{[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]+\}\}";
      let pos = text.search(regex);
      let cnt = 0;
      let p = [];
      while (pos != -1 && cnt < 100) {
        cnt++;
        let trunk = text.substring(pos + 2);
        let end = trunk.search("}}");
        let name = trunk.substring(0, end);
        p.push(name);
        text = trunk.substring(end + 2);
        pos = text.search(regex);
      }
      return p;
    }

    const setPlaceholderValue = (name, value) => {
      placeholderValues[name] = value;
      setPlaceholderValues(placeholderValues);

      const lines = (value.match(/\n/g) || '').length + 1
      placeholderLines[name] = lines;
      setPlaceholderLines(placeholderLines);

      let compiledGptUser = prompt?.gptUser;
      for (const [key, value] of Object.entries(placeholderValues)) {
        if (value != "") {
          compiledGptUser = compiledGptUser.replace("{{" + key + "}}", value);
        }
      }
      setCompiledGptUser(compiledGptUser);

      let compiledGptSystem = prompt.gptSystem;
      for (const [key, value] of Object.entries(placeholderValues)) {
        if (value != "") {
          compiledGptSystem = compiledGptSystem.replace("{{" + key + "}}", value);
        }
      }
      setCompiledGptSystem(compiledGptSystem);
    }

    useEffect(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, [messages, executing]);

    const execute = async (executingFollowUp) => {
      setExecuting(true);
      setFollowUp("");
      setFollowUpLines(1);
      let currentMessages = messages;
      if (currentMessages.length == 0 || !executingFollowUp) {
        if (currentMessages.length == 0) {
          currentMessages.push({role: "system", content: compiledGptSystem});
        }
        currentMessages.push({role: "user", content: compiledGptUser});
        //setMessages(currentMessages);s
      } else {
        currentMessages.push({role: "user", content: followUp});
      }

      setTopInputChanged(false);
      console.log(currentMessages);

      const response = await fetchExecute(props.token.tokenString, props.setToken, setLimitReached, promptId, currentMessages);
      if (response == null) {
        setExecuting(false);
        return;
      }
      let newMessage = {role: "assistant", content: response};
      setMessages([...currentMessages, newMessage]);

      setExecuting(false);

      //finding the history title
      var title = null;
      if (Object.entries(placeholderValues).length > 0) {
         title = Object.entries(placeholderValues)[0][1];
      }
      if (title == null && historyId != null) {
        title = findHistoryById(histories, historyId)?.title;
      }
      if (title == null) {
        title = prompt.title;
      }

      //saving the history
      var newHistoryId = await fetchPostHistory(props.token.tokenString, historyId, promptId, truncate(title, 16), [...currentMessages, newMessage]);
      setHistoryId(newHistoryId);
      var newHistories = histories;
      for (var i = 0; i < newHistories.length; i++) {
        console.log("copmaring " + newHistories[i].id + " to " + newHistoryId);
        if (newHistories[i].id == newHistoryId) {
          newHistories[i].messages = JSON.stringify([...currentMessages, newMessage]);
        }
      }
      console.log(newHistories);
      setHistories(newHistories);

      console.log(newMessage);
    }

    const newSession = async () => {
      setMessages([]);
      setHistoryId(null);
      setPlaceholderValues({});

      const histories = await fetchHistories(props.token.tokenString, promptId);
      setHistories(histories);
    }

    const deleteHistory = async (historyId) => {
      setMessages([]);
      setHistoryId(null);

      let newHistories = [];
      for (var i = 0; i < histories.length; i++) {
        if (histories[i].id != historyId) {
          newHistories.push(histories[i]);
        }
      }
      setHistories(newHistories);

      await fetchDeleteHistory(props.token.tokenString, historyId);
    }

    const adjustFollowUpHeight = async (value) => {
      const lines = (value.match(/\n/g) || '').length + 1
      setFollowUpLines(lines);
    }

    const changeTab = async (item) => {
      setHistoryId(item.id);
      setMessages(JSON.parse(item.messages));
      setPlaceholderValues({});

      const histories = await fetchHistories(props.token.tokenString, promptId);
      setHistories(histories);
    }

    const startRecording = () => {
      setRecording(true);
      SpeechRecognition.startListening({ continuous: true, language: 'de' });
    }

    const stopRecording = () => {
      SpeechRecognition.stopListening();
      setRecording(false);
      setRecordingProcessing(true);
      processTranscript(transcript);
      resetTranscript();
    }

    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const toggleRecording = () => {
      if (recording) {
        stopRecording();
      } else {
        startRecording();
      }
    }

    const processTranscript = async (transcript) => {
      let targetJsonFields = "";
      for (var i = 0; i < placeholders.length; i++) {
        targetJsonFields += "\"" + placeholders[i] + "\": \"\"";
        if (i != placeholders.length - 1) {
          targetJsonFields += ",";
        }
      }
      let targetJson = "{" + targetJsonFields + "}";
      let recordingPrompt = "Verwende diesen Text '" + transcript.replaceAll("\"", "\\\"") + "' um das folgende JSON zu befüllen: " + targetJson.replaceAll("\"", "\\\"") + ". Gibt nur das JSON und sonst nichts zurück.";
      let recordingMessages = "[{\"role\": \"user\", \"content\": \"" + recordingPrompt + "\"}]";

      const response = await fetchExecuteAny(props.token.tokenString, setLimitReached, recordingMessages);
      try {
        let responseJson = JSON.parse(response);
        for (const [key, value] of Object.entries(responseJson)) {
          setPlaceholderValue(key, value);
        }
      } catch (error) {
        console.log(error);
      }


      setRecordingProcessing(false);
      resetTranscript();
    }

    return(
      <div className={"box"}>
        {prompt == null ? <div className="spinnerBox"><br/><br/><AiOutlineLoading3Quarters className="spinner" /> Lade Prompt...</div> :
          <div>
            <div className={"promptHeader"}>
              <div className={"promptTitle"}>
                {
                  favorites.includes(promptId) ?
                  <BsStarFill onClick={(e) => {fetchFavorite(props.token.tokenString, prompt, false); setFavorites([])}} style={{color: "orange", fontSize: "20px", cursor: "pointer"}}/> :
                  <BsStar onClick={(e) => {fetchFavorite(props.token.tokenString, prompt, true); setFavorites([prompt.id])}} style={{color: "orange", fontSize: "20px", cursor: "pointer"}}/>
                } {prompt.title}
              </div>
              <div className={"promptStats"}>
                {prompt.usageCount} Interaktionen, <BsStarFill style={{color: "orange", fontSize: "12px"}} /> {prompt.favoriteCount}
                {props.token.roles?.includes("admin") || (user != null && user.id == prompt.ownerId) ?
                  <button className={"small"} onClick={(e) => document.location.href="/edit/" + promptId}><BsPencilFill /> Bearbeiten</button> :
                  <button className={"small"} onClick={(e) => document.location.href="/edit/" + promptId}><BsPencilFill /> Duplizieren</button>
                }
              </div>
            </div>
            {prompt.tags?.map(item => (
              <a href={"/tag/" + item}><div className={"tag"}>{item}</div></a>
            ))}
            <br/><br/>
            <b>Beschreibung:</b> <div className={"description"}>{prompt.description}</div><br/>
            {loadingHistory ?
              "" :
              <div>
                {histories?.length > 0 ? <div><br/><span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Hier sehen Sie Ihre Verläufe mit diesem Prompt.</span><br/><br/></div> : ""}
                {histories?.map(item => (
                  <div className={"tab " + (item.id == historyId ? "selected" : "")} onClick={(e) => {changeTab(item)}}>{item.title} <AiFillCloseCircle style={{verticalAlign: "bottom"}} onClick={(e) => {deleteHistory(item.id); e.stopPropagation()}} className={"closeTab"} /></div>
                ))}
              </div>
            }
            <br/>
            {
              !(browserSupportsSpeechRecognition && placeholders != null && messages.length == 0 && !limitReached) ? "" :
              <div>
                <button className={"recorder " + (recording ? "recording" : "")} onClick={(e) => toggleRecording()}>
                  {recordingProcessing ? <AiOutlineLoading3Quarters className="plainSpinner" /> : <>{recording ? <BiSolidMicrophone /> : <BiMicrophone />}</>}
                </button>
                {
                  !recording && !recordingProcessing ? <span className="plainMessage">Klicken Sie auf das Mikrofon, um die Spracheingabe zu nutzen</span> : ""
                }
                {
                  recording ? <span className="plainMessage">{transcript}</span> : ""
                }
                <br/>
              </div>
            }
            {placeholders == null ? <div className="spinnerBox"><AiOutlineLoading3Quarters className="spinner" /> Lade Platzhalter...</div> : placeholders?.map(item => (
              <div className={"placeholderBox"}>
                <label>{item}</label>
                <textarea value={placeholderValues[item]} style={{height: Math.min(((placeholderLines[item] == undefined ? 1 : placeholderLines[item]) - 1) * 19 + 23, 150)}} placeholder={"Geben Sie '" + item + "' hier ein, um den Prompt zu befüllen"} onChange={(e) => {setPlaceholderValue(item, e.target.value); setTopInputChanged(true)}}/>
              </div>
            ))}
            <br/>
            {compiledGptSystem == "" ? "" : <div><textarea value={compiledGptSystem} onChange={(e) => setCompiledGptSystem(e.target.value)} /></div>}
            <textarea value={compiledGptUser} onChange={(e) => {setCompiledGptUser(e.target.value); setTopInputChanged(true)}} /><br/>
            {(messages.length > 2 || executing || limitReached) && (!topInputChanged) ? "" : <div><br/><button onClick={(e) => execute(false)}>Senden</button><br/><br/></div> }

            {messages.length <= 2 ? "" : messages?.map((item, index) => (
              <div>{index < 2 ? "" :
                <div className={"messageBox"}>
                  <div className={"messageIcon " + (item.role == "user" ? "" : "botIcon")}>{item.role == "user" ? props.token.user[0].toUpperCase() : "h"}</div>
                  <div className={"messageContent"}><ReactMarkdown>{item.content}</ReactMarkdown></div>
                </div>}
              </div>
            ))}
            <br/>
            {messages.length > 2 ? <><div className={"headerLeft"}></div><div className={"headerRight"}><button className={"small"} onClick={(e) => newSession()}><BiRefresh /> Neue Session</button></div></> : ""}
            {executing ? <div><br/><div className="spinnerBox"><AiOutlineLoading3Quarters className="spinner" /> Lade...</div><br/></div> : ""}
            {loadingHistory ? <div><br/></div> : ""}
            <br/><br/><br/><br/>
            <div className={"followUp"}>
              {messages.length <= 2 || limitReached ? "" :
                <div>
                  <textarea style={{height: Math.min((followUpLines - 1) * 19 + 23, 150)}} placeholder="Antwort..." className={"followUp"} onKeyPress={(ev) => { if (ev.key === "Enter" && !ev.shiftKey) { ev.preventDefault(); execute(true); }}} value={followUp} onChange={(e) => {setFollowUp(e.target.value); adjustFollowUpHeight(e.target.value)}} />
                  <button onClick={(e) => execute(true)}>Senden</button>
                </div>
              }
              {limitReached ? <span className="message"><br/>Ihr Tokenlimit von {user.tokenLimit} für den heutigen Tag ist bereits aufgebraucht!<br/><br/> </span> : ""}
            </div>
          </div>
        }
      </div>
    )
}

function fetchPrompt(token, setToken, promptId) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/' + promptId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchExecute(token, setToken, setLimitReached, promptId, messages) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(messages)
    };

    return fetch(Config.apiBaseUrl + '/prompt/execute/' + promptId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setLimitReached(true);
            return null;
          }
          return response.text()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchExecuteAny(token, setLimitReached, messages) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: messages
    };

    return fetch(Config.apiBaseUrl + '/executeAny', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setLimitReached(true);
            return null;
          }
          return response.text()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchFavorites(token) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/favorites', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}


function fetchFavorite(token, prompt, addFavorite) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/favorite/' + prompt.id + '/' + addFavorite, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchHistories(token, promptId) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/history/' + promptId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          console.log(data);
          return data;
        }
      );
}

function fetchPostHistory(token, historyId, promptId, title, messages) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({id: historyId, title: title, messages: JSON.stringify(messages)})
    };

    return fetch(Config.apiBaseUrl + '/prompt/' + promptId + '/histories', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.text();
        }
      )
      .then(
        data => {
          console.log(data);
          return data;
        }
      );
}

function fetchDeleteHistory(token, historyId) {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/history/' + historyId, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUser(token, setToken) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/self', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

function findHistoryById(histories, id) {
  for (var i = 0; i < histories.length; i++) {
    if (histories[i].id == id) {
      return histories[i];
    }
  }
}

export default Prompt;
