import './App.css';

import Home from './Home.js';
import Header from './Header.js';
import Login from './Login.js';
import Prompt from './Prompt.js';
import Edit from './Edit.js';
import Tag from './Tag.js';
import Profile from './Profile.js';
import Admin from './Admin.js';
import EditUser from './EditUser.js';
import SetPassword from './SetPassword.js';
import ForgotPassword from './ForgotPassword.js';
import AllPrompts from './AllPrompts.js';
import Speech from './Speech.js';
import Chat from './Chat.js';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

    document.title = "hPrompt";

    const [token, setToken] = useState(null);

     useEffect(() => {
          let token = localStorage.getItem("session");
          if (token != undefined) {
            setToken(JSON.parse(token).token);
          } else {
            setToken(null);
          }
          //alert(token);
     }, []);

    if (token === null) {
      return <BrowserRouter>
          <Routes>
            <Route path="/setPassword/:secret" element={<><SetPassword/></>} />
            <Route path="/forgotPassword/" element={<><ForgotPassword/></>} />
            <Route path="*" element={<Login setToken={setToken} />} />
          </Routes>
        </BrowserRouter>
    } else if (token != null) {
      return <BrowserRouter>
          <Routes>
            <Route path="/" element={<><Header token={token}/><Home setToken={setToken} token={token} /></>} />
            <Route path="/prompt/:promptId" element={<><Header token={token}/><Prompt setToken={setToken}  token={token} /></>} />
            <Route path="/chat/" element={<><Header token={token}/><Chat setToken={setToken}  token={token} /></>} />
            <Route path="/edit/:promptId" element={<><Header token={token}/><Edit token={token} /></>} />
            <Route path="/edit/" element={<><Header token={token}/><Edit token={token} /></>} />
            <Route path="/profile/" element={<><Header token={token}/><Profile setToken={setToken} token={token} /></>} />
            <Route path="/admin/" element={<><Header token={token}/><Admin setToken={setToken} token={token} /></>} />
            <Route path="/user/edit/:userId" element={<><Header token={token}/><EditUser setToken={setToken} token={token} /></>} />
            <Route path="/user/create" element={<><Header token={token}/><EditUser setToken={setToken} token={token} /></>} />
            <Route path="/tag/:tag" element={<><Header token={token}/><Tag token={token} /></>} />
            <Route path="/prompts/all/:userId" element={<><Header token={token}/><AllPrompts setToken={setToken} token={token} /></>} />
            <Route path="/prompts/all/" element={<><Header token={token}/><AllPrompts setToken={setToken} token={token} /></>} />
            <Route path="/setPassword/:secret" element={<><SetPassword/></>} />
            <Route path="/speech/" element={<><Speech setToken={setToken} token={token} /></>} />
          </Routes>
        </BrowserRouter>
    } else {
      return "";
    }
}

export default App;
