import './App.css';

import Config from './Config.js';
import Thumb from './Thumb.js';

import HuemerGroup from './Thumb.js';

import React, { useState, useEffect } from 'react';

import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";

function Home(props) {

    const [query, setQuery] = useState("");
    const [searchResults, setSearchResults] = useState(null);
    const [searching, setSearching] = useState(false);

    const [tags, setTags] = useState(null);
    const [favorites, setFavorites] = useState(null);
    const [prompts, setPrompts] = useState(null);

    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedPrompts, setSelectedPrompts] = useState(null);

    const [user, setUser] = useState(null);

    useEffect(() => {
      load();
    }, []);

    const search = async (query) => {
       if (query.length > 0) {
        setSearching(true);
        const result = await fetchSearch(props.token.tokenString, query);
        if (query.length > 0) {
          setSearchResults(result);
          setSearching(false);
        }
       } else {
        setSearchResults(null);
        setSearching(false);
       }
    }

    useEffect(() => {
      if (query.length <= 0) {
        setSearchResults(null);
      }
    }, [query, searchResults]);

    const load = () => {
      loadTagsForUser();
      loadFavorites();
      loadOwn();
      loadUser();
    }

    const loadTagsForUser = async () => {
      const tags = await fetchOwnTags(props.token.tokenString);
      setTags(tags);
    }

    const loadFavorites = async () => {
      const favorites = await fetchFavorites(props.token.tokenString);
      setFavorites(favorites);
    }

    const loadUser = async () => {
      const user = await fetchUser(props.token.tokenString, props.setToken);
      setUser(user);
    }

    const loadOwn = async () => {
      const own = await fetchOwn(props.token.tokenString);
      setPrompts(own);
      if (selectedTag == null) {
       setSelectedPrompts(own);
      }
    }

    const toggleTag = (tag) => {
      if (selectedTag == tag) {
        setSelectedTag(null);
      } else {
        setSelectedTag(tag);
      }
    }

    useEffect(() => {
      if (selectedTag == null) {
        setSelectedPrompts(prompts);
      } else {
        let p = [];
        for (var i = 0; i < prompts.length; i++) {
          if (prompts[i].tags.includes(selectedTag)) {
            p.push(prompts[i]);
          }
        }
        setSelectedPrompts(p);
      }
    }, [selectedTag]);

    return(
      <div className={"box"}>
        <div className={"slogan"}>
          🤖 hPrompt - die kollaborative Prompt-Library der Huemer-Group
          <div className={"smallSlogan"}>Wählen Sie die besten Prompts aus unserer Sammlung aus und bringen Sie Ihr AI-Erlebnis auf ein neues Level!</div>
          <a href="/chat/" className={"boldButton"}>Jetzt direkt Chatten</a>
        </div>
        <input placeholder="Suche nach Prompts..." className={"search"} value={query} onChange={(e) => {setQuery(e.target.value); search(e.target.value)}} />
        <div className={"searchBox"}>
          {searching ? <div className="spinnerBox"><AiOutlineLoading3Quarters className="spinner" /> Suche...</div> : ""}
          {searchResults == null || searching ? "" : searchResults?.map(item => (
            <a href={"/prompt/" + item.id}>
              <Thumb prompt={item} userOwnsPrompt={user?.id == item.ownerId} /><br/>
            </a>
          ))}
        </div><br/>
        <b>Ihre Kategorien</b><br/><br/>
        <span className="hint"><FaInfoCircle style={{fontSize: "13px", verticalAlign: "unset"}} /> Klicken Sie auf einer der Kategorien, um nur Prompts aus dieser anzuzeigen.</span>
        <br/><br/>
        {tags == null || selectedPrompts == null  ? "" : tags?.map(item => (
          <a href={"#"} onClick={(e) => toggleTag(item)}><div className={"tag " + (selectedTag != null && selectedTag != item ? "tagUnselected " : "") + (selectedTag != null && selectedTag == item ? "tagSelected" : "")}>{item}</div></a>
        ))}
        {selectedPrompts != null && selectedPrompts.length == 0 ? <div><br/><span className="hint">Diese Kategorie enthält noch keine Prompts</span></div> : ""}
        <br/><br/>
        {selectedPrompts == null ? <div className="spinnerBox"><AiOutlineLoading3Quarters className="spinner" /> Lade Prompts...</div> : selectedPrompts?.map(item => (
          <a href={"/prompt/" + item.id}><Thumb prompt={item} userOwnsPrompt={user?.id == item.ownerId} className={"thumbSmall"} /></a>
        ))}
        <br/><br/>
        {
          favorites == null || favorites.length == 0 ? "" :
          <div>
            <b>Favoriten</b><br/><br/>
            {favorites == null ? <div className="spinnerBox"><AiOutlineLoading3Quarters className="spinner" /> Lade Favoriten...</div> : favorites?.map(item => (
              <a href={"/prompt/" + item.id}><Thumb prompt={item} className={"thumbSmall"} /></a>
            ))}
          </div>
        }
        <br/><br/><br/><br/>
      </div>
    )

}

function fetchSearch(token, query) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/search/' + query, requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchOwnTags(token) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/ownTags', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchOwn(token) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/own', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchFavorites(token) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/prompt/favorites', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

function fetchUser(token, setToken) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch(Config.apiBaseUrl + '/user/self', requestOptions)
      .then(
        response => {
          if (!response.ok) {
            setToken(null);
            return null;
          }
          return response.json()
        }
      )
      .then(
        data => {
          return data;
        }
      );
}

export default Home;
